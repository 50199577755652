const isEmpty = require('lodash/isEmpty');
const isString = require('lodash/isString');

const NO_SRC = 'no_src';
const DEFAULT_IMAGE = 'default.png';
const STATIC_MARKERS = ['builder/', 'blocks/', 'themes/', 'static/'];

const regExp = {
  staticDomain: () => /(https?:\/\/)?static\.(siteplus|edit)\.[^"/]+\//gi,
  fileStorage: () => /(https?:\/\/)?(\w.*\/)?filestorage-api-service(\/)?(\.siteplus\.[^/]+)?\//gi,
  externalStorage: () => /(https?:\/\/)?(storage\.googleapis\.com|images\.unsplash\.com)+\//gi,
};

/**
 * Function for get related path
 * @param {string} link
 * @return {string}
 */
const getRelatedPath = (link) => {
  if (!link || !isString(link)) return link;

  const linkWithoutDoubleSlashes = link.replace(/([^:]\/)\/+/g, '$1');

  return linkWithoutDoubleSlashes[0] === '/'
    ? linkWithoutDoubleSlashes.slice(1)
    : linkWithoutDoubleSlashes;
};

/**
 * Function for check if static link
 * @param {string} link
 * @return {boolean}
 */
const checkStatic = (link) => {
  if (!link || !isString(link)) return false;

  const relatedPath = getRelatedPath(link);

  return STATIC_MARKERS.some((marker) => relatedPath.startsWith(marker));
};

/**
 * Function for check for external link
 * @param {string} link
 * @return {boolean}
 */
const checkExternal = (link) => {
  if (!link || !isString(link)) return false;

  return link.startsWith('http');
};

/**
 * Function for convert link to correct
 * @param {string} src
 * @param {Object} urlPrefixes
 * @param {Object} staticData
 * @return {string}
 */
const convertSrcToCorrect = (src = '', urlPrefixes = {}, staticData = {}, options) => {
  if (!src || !isString(src)) return src;

  const { static: staticApi, fileStorageService, usersFilesPublicBucket } = urlPrefixes;
  const { isStaticPublished = false, staticDir = '' } = staticData;
  const { contentType } = options || {};

  if (src === NO_SRC) return `${staticApi}/${DEFAULT_IMAGE}`;

  const fromStatic = regExp.staticDomain().test(src);
  const fromFileStorage = regExp.fileStorage().test(src);
  const fromExternalStorage = regExp.externalStorage().test(src);

  const isExternal = checkExternal(src);
  const isStaticNotPublished = fromStatic && !isStaticPublished;
  const isFileStorageNotPublished = fromFileStorage && !isStaticPublished;
  const isExternalStorageNotPublished = fromExternalStorage && !isStaticPublished;

  if (isExternal
    && (!fromStatic || isStaticNotPublished)
    && (!fromFileStorage || isFileStorageNotPublished)
    && (!fromExternalStorage || isExternalStorageNotPublished)
  ) {
    if (isFileStorageNotPublished
      && usersFilesPublicBucket) return src.replace(regExp.fileStorage(), `${usersFilesPublicBucket}/`);

    return src;
  }

  if (isStaticPublished) {
    let clearSrc = src;

    if (fromStatic) {
      clearSrc = src.replace(regExp.staticDomain(), '');
    } else if (fromFileStorage) {
      clearSrc = src.replace(regExp.fileStorage(), '');
    } else if (fromExternalStorage) {
      clearSrc = src.replace(regExp.externalStorage(), '');

      if (contentType === 'image/svg+xml' && !clearSrc.includes('.svg')) {
        clearSrc = `${clearSrc}.svg`;
      }
    }

    let pureSrc = clearSrc.startsWith('/') ? clearSrc.slice(1) : clearSrc;
    const pureStaticDir = staticDir.startsWith('/') ? staticDir.slice(1) : staticDir;

    if (pureSrc.startsWith(pureStaticDir)) return `/${pureSrc}`;

    const pureSrcParts = pureSrc.split('?');

    if (fromExternalStorage && pureSrcParts.length > 1) {
      // eslint-disable-next-line prefer-destructuring
      pureSrc = pureSrcParts[0];
    }

    return `/${pureStaticDir}/${pureSrc}`;
  }

  if (isEmpty(urlPrefixes)) return src;
  if (checkStatic(src)) return `${staticApi}/${getRelatedPath(src)}`;

  return `${(usersFilesPublicBucket || fileStorageService)}/${getRelatedPath(src)}`;
};

module.exports = {
  NO_SRC,
  DEFAULT_IMAGE,
  STATIC_MARKERS,
  getRelatedPath,
  checkStatic,
  checkExternal,
  convertSrcToCorrect,
};
