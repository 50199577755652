import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import getTranslations from '../../../../../i18n';
import getBadges from '../utils/getBadges';
import isAvailable from '../utils/isAvailable';
import isFewLeft from '../utils/isFewLeft';
import isOnSale from '../utils/isOnSale';
import isOutOfStock from '../utils/isOutOfStok';

class ProductModel {
  constructor() {
    this.i18next = getTranslations();

    this.id = null;

    this.name = '';

    this.title = '';

    this.excerpt = '';

    this.description = '';

    this.quantity = 0;

    this.price = 0;

    this.priceFormatted = '';

    this.priceWithOptions = 0;

    this.priceWithOptionsFormatted = '';

    this.compareToPrice = 0;

    this.compareToPriceFormatted = '';

    this.compareToPriceWithOptions = 0;

    this.compareToPriceWithOptionsFormatted = '';

    this.discountFormatted = '';

    this.sku = null;

    this.options = {};

    this.category = '';

    this.limit = 100;

    this.unlimited = false;

    this.inStock = false;

    this.isDigital = false;

    this.keyword = [];

    this.images = [];

    this.weight = 0;

    this.width = 0;

    this.height = 0;

    this.length = 0;

    this.shipping = null;

    this.metaTitle = '';

    this.metaDescription = '';

    this.isSubscriptionAvailable = false;

    this.subscriptionDetails = null;

    this.subscriptionTitle = '';

    this.subscriptionText = '';

    this.subscriptionDiscount = 0;

    this.withSubscriptionDiscount = false;

    this.banner = '';

    this.bannerAvailable = false;

    this.bannerColor = 'var(--color_textAlt)';
  }

  isAvailable = () => isAvailable(this);

  isOutOfStock = () => isOutOfStock(this);

  isOnSale = () => isOnSale(this);

  isFewLeft = () => isFewLeft(this);

  cantAddToCartMore = (countInCart) => {
    const { unlimited, quantity } = this;

    if (unlimited) return false;

    return (!this.isOutOfStock() && countInCart && quantity <= countInCart);
  };

  withGallery = () => this.images.length > 0;

  withShipping = () => isArray(this.shipping) && !isEmpty(this.shipping);

  getTranslate = (key) => this.i18next[key] || key;

  getBadges = (isNativeStore) => getBadges(isNativeStore, {
    isOnSale: this.isOnSale(),
    isOutOfStock: this.isOutOfStock(),
    isDigital: this.isDigital,
    isFewLeft: this.isFewLeft(),
  }, this.getTranslate);
}

export default ProductModel;
