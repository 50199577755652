import isString from 'lodash/isString';
import { Node } from 'slate';

import getPrepareContent from '@sp/json-schema/scripts/fix/mutators/blog/utils/getPrepareContent';

/**
 *
 * @param {string|JSON} excerpt
 * @returns {String}
 */
const getPlainExcerpt = (excerpt) => {
  if (!isString(excerpt)) return '';

  try {
    const excerptData = JSON.parse(excerpt);

    const excerptValue = getPrepareContent(excerptData);
    const serialize = (nodes) => nodes.map((n) => Node.string(n)).join('\n');

    return serialize(excerptValue);
  } catch {
    return excerpt;
  }
};

export default getPlainExcerpt;
