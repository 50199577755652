import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';

import { FB } from '../../constants';

import { FILE_DOWNLOAD_ATTR } from './constants';
import { isFullUrl } from './urlInfo';

const {
  getRelatedPath,
  checkStatic,
  checkExternal,
  convertSrcToCorrect,
} = require('./convertSrcToCorrect');

const homeurl = '/';

/**
 * Function for get home page url
 * @return {string}
 */
export const getHomePage = function (menu) {
  if (!menu) return '';

  const items = [...menu.list, ...menu.unlinked];

  if (items.length === 0) return '';

  let homepage = items[0].tag;

  items.forEach((el) => {
    if (el.homepage) {
      homepage = el.tag;
    }
  });

  return homepage;
};

/**
 * Returns link with trailing slash for anchors to avoid wrong Safari 301 redirects
 * @param {string} link
 * @param {string} anchor
 * @return {string}
 */
export const withAnchor = function (link, anchor) {
  const baseUrl = link || '';

  if (!anchor) return baseUrl;

  const slash = baseUrl && baseUrl[baseUrl.length - 1] !== '/' ? '/' : '';
  const sign = anchor && !anchor.startsWith('#') ? '#' : '';

  return `${baseUrl}${slash}${sign}${anchor}`;
};

/**
 * Function for create correct link with hash
 * @param {Array} menuItems
 * @param {string} hash
 * @param {string} anchor
 * @param {string} pageId
 * @param {boolean=} isNav
 * @return {string}
 */
const pageHashToLink = (menuItems, hash, anchor, pageId, isNav) => {
  let activeTag = null;
  let isExistLink = false;

  const iterateMenu = (menu, path) => {
    if (Array.isArray(menu) && menu.length > 0) {
      menu.forEach((menuItem) => {
        if (menuItem.hash === hash) {
          activeTag = menuItem.homepage ? homeurl : `${path}${menuItem.tag}`;
          isExistLink = true;
        } else if (menuItem.children) {
          const isParentHome = menuItem.homepage && menuItem.children.some(
            (item) => item.hash === hash
          );
          const parentPath = `${path}${isParentHome
            ? ''
            : `${menuItem.tag}/`}`;

          iterateMenu(menuItem.children, parentPath);
        }
      });
    } else if (isPlainObject(menu)) {
      Object.values(menu).forEach((item) => iterateMenu(item, path));
    }
  };

  if (pageId === hash && !isNav) {
    activeTag = '';
    isExistLink = true;
  } else {
    iterateMenu(menuItems, '/');
  }

  const link = !activeTag && !isString(activeTag) ? homeurl : activeTag;

  return isExistLink ? withAnchor(link, anchor) : homeurl;
};

/**
 * Function for create correct link with hash
 * @param {Object} data
 * @param {string} data.href
 * @param {string} data.anchor
 * @param {{}} data.menu
 * @param {string} data.pageId
 * @param {boolean=} data.isNav
 * @return {string}
 */
const getHref = function ({
  href,
  anchor,
  menu,
  pageId,
  isNav,
}) {
  const allLinks = menu && [...menu.list, ...menu.unlinked];
  const sectionAnchor = anchor && `#s-${anchor}`;

  return pageHashToLink(allLinks, href, sectionAnchor, pageId, isNav);
};

/**
 * Function for normalise facebook link
 * @param {string} href
 * @return {string}
 */
export const normalizeFBhref = function (href = '') {
  if (href && !href.includes('http')) {
    if (href.indexOf('facebook.com') === 0) {
      // eslint-disable-next-line no-param-reassign
      href = `https://${href}`;
    } else if (href.indexOf('fb.com') === 0) {
      // eslint-disable-next-line no-param-reassign
      href = href.replace('fb.com', FB);
    } else {
      // eslint-disable-next-line no-param-reassign
      href = `${FB}/${href}`;
    }
  }

  return href.slice(-1) === '/' ? href.slice(0, -1) : href;
};

/**
 * Function for filter digits only
 * @param {string} value
 * @return {string}
 */
export const getOnlyDigits = (value) => {
  if (!isString(value)) return '';

  return value.replace(/\D/g, '');
};

/**
 * Function for normalise link
 * @param {{}} data
 * @param {string} data.type
 * @param {string} data.href
 * @param {string} data.anchor
 * @param {{}} data.menu
 * @param {string} data.pageId
 * @param {boolean=} data.isNav
 * @return {string}
 */
export const getLink = function ({
  type,
  href,
  anchor,
  menu,
  pageId,
  isNav,
}) {
  let link = (href || '').trim();

  if (link && type) {
    switch (type.toLowerCase()) {
      case 'anchor':
      case 'page': {
        link = getHref({
          href: link,
          anchor,
          menu,
          pageId,
          isNav,
        });

        break;
      }
      case 'email': {
        link = link.startsWith('mailto:') ? link : `mailto:${link}`;

        break;
      }
      case 'phone': {
        link = `tel:${link.includes('+') ? '+' : ''}${getOnlyDigits(link)}`;

        break;
      }
      case 'url': {
        if (!isFullUrl(link) && link !== '/') link = `http://${link}`;

        break;
      }
      default: {
        break;
      }
    }
  }

  return link || undefined;
};

/**
 * Function for convert link to download link
 * @param {string} link
 * @return {string}
 */
export const convertLinkToDownloadType = (link) => {
  if (!link || !isString(link) || link.includes(FILE_DOWNLOAD_ATTR)) return link;

  return link + FILE_DOWNLOAD_ATTR;
};

/**
 * Function for extract filename
 * @param {string} link
 * @return {string}
 */
export const extractNameFromFileLink = (link) => {
  if (!link || !isString(link) || link.length === 0) return link;

  const fileName = link.split('/').pop();
  const downloadAttrIndex = fileName.indexOf(FILE_DOWNLOAD_ATTR);

  return downloadAttrIndex >= 0
    ? fileName.slice(0, Math.max(0, downloadAttrIndex))
    : fileName;
};

export {
  getRelatedPath,
  checkStatic,
  checkExternal,
  convertSrcToCorrect,
};

/**
 * Function for get link props
 * @param {Object} data
 * @param {string} data.link
 * @param {string} data.linkType
 * @param {Object} data.urlPrefixes
 * @param {Object} data.staticData
 * @param {{}} data.menu
 * @param {string} data.pageId
 * @param {string} anchor
 * @return {Object}
 */
export const getLinkProps = ({
  link,
  linkType,
  urlPrefixes = {},
  staticData = {},
  menu,
  pageId,
  anchor,
}) => {
  if (!link || !isString(link)) return { href: null };

  const href = getLink({
    type: linkType,
    href: link,
    anchor,
    menu,
    pageId,
  });

  if (isString(linkType) && linkType.toLowerCase() !== 'file') return { href };

  const fileLink = convertSrcToCorrect(href, urlPrefixes, staticData);

  return {
    href: convertLinkToDownloadType(fileLink),
    download: extractNameFromFileLink(fileLink),
  };
};
