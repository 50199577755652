import isEmpty from 'lodash/isEmpty';

import dom from '../../../wrapper/DomWrapper';

import {
  GALLERY_CONTAINER_SELECTOR,
  IMAGE_CONTAINER_SELECTOR,
  IMAGE_GALLERY_CONTAINER_SELECTOR,
  IMAGE_INNER_BORDER_SELECTOR, IMAGE_SELECTOR,
} from './constants';

const convertBorderRadius = (container, borderRadius = 0) => {
  if (!container) return borderRadius;

  const containerWidth = dom.getElementWidth(container);
  const containerHeight = dom.getElementHeight(container);

  return Math.min(containerWidth, containerHeight) * (borderRadius / 100);
};

export const getBorderRadiusSettings = ({
  container = null,
  isMixedRadius,
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
  borderBottomLeftRadius,
}) => {
  let updatedBorderTopLeftRadius = 0;
  let updatedBorderTopRightRadius = 0;
  let updatedBorderBottomRightRadius = 0;
  let updatedBorderBottomLeftRadius = 0;
  let updatedBorderRadius = 0;

  if (isMixedRadius) {
    updatedBorderTopLeftRadius = convertBorderRadius(container, borderTopLeftRadius);
    updatedBorderTopRightRadius = convertBorderRadius(container, borderTopRightRadius);
    updatedBorderBottomRightRadius = convertBorderRadius(container, borderBottomRightRadius);
    updatedBorderBottomLeftRadius = convertBorderRadius(container, borderBottomLeftRadius);
  } else {
    updatedBorderRadius = convertBorderRadius(container, borderRadius);
  }

  return {
    ...!isMixedRadius && {
      borderRadius: `${updatedBorderRadius}px`,
    },
    ...isMixedRadius && {
      borderTopLeftRadius: `${updatedBorderTopLeftRadius}px ${updatedBorderTopLeftRadius}px`,
      borderTopRightRadius: `${updatedBorderTopRightRadius}px ${updatedBorderTopRightRadius}px`,
      borderBottomRightRadius: `${updatedBorderBottomRightRadius}px ${updatedBorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${updatedBorderBottomLeftRadius}px ${updatedBorderBottomLeftRadius}px`,
    },
  };
};

/**
 * Util for setting inline border radius to an image container
 * Shows element after calculation
 * @param {Element} image
 */
export const setImageBorderRadius = (image) => {
  if (!image) return;

  const container = image.closest(IMAGE_CONTAINER_SELECTOR);

  if (!container || !container.dataset?.settings) return;

  const settings = JSON.parse(container.dataset?.settings);

  if (isEmpty(settings)) return;

  const {
    isMixedRadius,
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
  } = settings;

  if (!borderRadius && !isMixedRadius) return; // ToDo add other props for correct work

  const innerBorder = dom.getElement(IMAGE_INNER_BORDER_SELECTOR, container);
  const borderRadiusSettings = getBorderRadiusSettings({
    container,
    isMixedRadius,
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
  });

  dom.updateStyle(container, borderRadiusSettings);

  if (innerBorder) dom.updateStyle(innerBorder, borderRadiusSettings);

  dom.showOpacity(container);
};

/**
 * Util for rechecking border size (used on resize event)
 */
export const recalculateImageBorder = () => {
  const elImages = dom.getCollection(IMAGE_SELECTOR);

  if (isEmpty(elImages)) return;

  elImages.forEach((image) => {
    setImageBorderRadius(image);
  });
};

export const setGalleryImageBorderRadius = (image) => {
  if (!image) return;

  const gallery = image.closest(GALLERY_CONTAINER_SELECTOR);
  const wrapper = image.closest(IMAGE_GALLERY_CONTAINER_SELECTOR);

  if (!gallery || !gallery.dataset?.settings) return;

  const settings = JSON.parse(gallery.dataset?.settings);

  if (isEmpty(settings)) return;

  const { borderRadius, showCaption } = settings;

  if (!borderRadius) return;

  const borderRadiusInPx = convertBorderRadius(wrapper, borderRadius);

  dom.updateStyle(wrapper, {
    borderRadius: `${borderRadiusInPx}px`,
    margin: (borderRadius > 0 && showCaption) ? '8px' : null,
  });
};
