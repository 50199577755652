import { IMAGE_SHAPE_OPTIONS } from '../../../../../components/Booking/constants';
import getStateValue from '../../../../helpers/getStateValue';
import dom from '../../../../wrapper/DomWrapper';
import lazyLoad from '../../../LazyLoad';
import { BUTTON_SELECTOR, IMAGE_ERROR, IMAGE_SELECTOR } from '../../constants';
import { getBackground, getColor } from '../../utils';

class Product {
  element = null;

  model = {};

  settings = {};

  get modelSettings() {
    return this.model?.settings || {};
  }

  constructor(element, model) {
    this.element = element;
    this.model = model;
    this.elImageWrapper = dom.getElement(`.${IMAGE_SELECTOR}`, this.element);
    this.elImage = dom.getElement(`.${IMAGE_SELECTOR} > img`, this.element);
    this.elBookingButton = dom.getElement(`.${BUTTON_SELECTOR}`, this.element);
    this.isBackupMode = getStateValue('isBackup', false);

    try {
      this.settings = element.dataset.settings ? JSON.parse(element.dataset.settings) : {};
    } catch (error) {
      console.error(error);
    }
  }

  init = () => {
    if (!this.isBackupMode) this.initBookingButton();

    this.applyColorStyles();
    this.applyBackgroundStyles();
    this.initLazyLoad();
  };

  initBookingButton = () => {
    const { bookingUrl } = this.modelSettings;
    const { id } = this.model || {};

    if (!bookingUrl || !id || !this.elBookingButton) return;

    dom.on(this.elBookingButton, 'click', () => {
      dom.window.location.assign(`/${bookingUrl}/service-${id}`);
    });
  };

  initLazyLoad = () => {
    if (!this.elImageWrapper) return;
    if (!this.elImage) {
      dom.addClass(this.elImageWrapper, IMAGE_ERROR);

      return;
    }

    this.elImage.onerror = () => {
      dom.addClass(this.elImageWrapper, IMAGE_ERROR);
    };

    setTimeout(() => lazyLoad(), 0);
  };

  applyColorStyles = () => {
    const { colors } = this.settings;
    const color = getColor(colors || {});

    dom.updateStyle(this.element, { color });
  };

  applyBackgroundStyles = () => {
    const { imageShape, imageRatio } = this.modelSettings;

    if (!this.elImageWrapper || imageShape === IMAGE_SHAPE_OPTIONS.CIRCLE) return;

    const background = getBackground(imageRatio);

    dom.updateStyle(this.elImageWrapper, background);
  };
}

export default Product;
