import ProviderWrapper from './Wrapper';
import dom from '../../../wrapper/DomWrapper';

import { checkIsYouTubeThumbnailCorrect } from '../utils';
import { MAX_YOUTUBE_THUMBNAIL } from '../constants';

class VideoYouTube extends ProviderWrapper {
  async init() {
    const {
      id,
      autoplay,
      color,
      removeControls,
      isBackground,
      src,
      fs,
    } = this.dataParams;
    const isExist = await this.checkIsVideoExist(id);

    const isVideoExists = this.renderVideoOnPage(isExist);

    if (isVideoExists) return;

    if (!isExist) return;

    this.player = this.connectVideoDefault();
    this.setYouTubeThumbnail(src);
    this.addPreviewImage(this.player);

    this.player.source = {
      type: 'video',
      sources: [
        {
          src: id,
          provider: 'youtube',
        },
      ],
    };

    this.player.config.youtube = {
      ...this.player.config.youtube,
      autopause: 0,
      color,
      disablekb: 1,
      rel: 0,
      showinfo: 0,
      resetOnEnd: 1,
      fs: this.isFullScreen(isBackground, fs),
      iv_load_policy: 3,
      controls: removeControls || isBackground ? 0 : 1,
      mute: isBackground ? 1 : autoplay,
      cc_load_policy: isBackground && 0,
      autoplay: isBackground && 1,
      modestbranding: isBackground && 1,
      origin: dom.window.location.origin,
    };

    this.player.elements.poster.style.display = 'none';
  }

  isFullScreen = (isBackground, fullScreen) => (!isBackground && fullScreen ? 1 : 0);

  setYouTubeThumbnail = (src) => {
    if (!src || !src.includes(MAX_YOUTUBE_THUMBNAIL)) return;

    dom.updateStyle(this.video, { opacity: 0 });

    checkIsYouTubeThumbnailCorrect(src)
      .then((isCorrect) => {
        if (isCorrect) return;

        const hdThumbnail = src.replace(MAX_YOUTUBE_THUMBNAIL, '0');
        const elImage = dom.getElement('img', this.video);

        elImage.setAttribute('data-src', hdThumbnail);
        elImage.setAttribute('src', hdThumbnail);
      })
      .finally(() => dom.updateStyle(this.video, { opacity: 1 }));
  }

  checkIsVideoExist = async (id) => {
    if (!id) return Promise.resolve(false);

    const src = `https://img.youtube.com/vi/${id}/0.jpg`;

    const isExist = await checkIsYouTubeThumbnailCorrect(src);

    return isExist;
  }
}

export default VideoYouTube;
