import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import {
  ASPECT_RATIO,
  BUTTON_LINE_HEIGHT,
  BUTTON_PADDING_VERTICAL,
  PHOTO_MARGIN_BOTTOM,
  PRICE_MARGIN_BOTTOM,
  PRODUCT_404_ERROR,
  SQUARE_1,
  TEXT_MARGIN_BOTTOM,
} from '../../../../../components/Ecommerce/Ecwid/Custom/constants';
import ecommerce from '../../../../ecommerce/ecwid/custom';
import checkProjectWithAnimation from '../../../../helpers/checkProjectWithAnimation';
import ieChangeCssVar from '../../../../helpers/ieChangeCssVar';
import { connectInView } from '../../../../helpers/inView';
import parseRootFloat from '../../../../helpers/parseRootFloat';
import dom from '../../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../../wrapper/WidgetWrapper';
import ItemsRenderer from '../../../ItemsView/renderer';

import SingleProduct from './SingleProduct';
import { getImageSize } from './utils';

export const getProductMinHeight = (
  photoWidth = 0,
  display = [],
  aspectRatio = SQUARE_1
) => {
  const buttonFontSize = parseRootFloat('--btn_primary_fontSize');
  const priceFontSize = parseRootFloat('--title_subHeading_fontSize');
  const priceLineHeight = parseRootFloat('--title_subHeading_lineHeight');
  const textFontSize = parseRootFloat('--text_fontSize');
  const textLineHeight = parseRootFloat('--text_lineHeight');

  const textHeight = TEXT_MARGIN_BOTTOM + textFontSize * textLineHeight;

  const heights = {
    photo: PHOTO_MARGIN_BOTTOM + photoWidth / aspectRatio,
    button: BUTTON_PADDING_VERTICAL + buttonFontSize * BUTTON_LINE_HEIGHT,
    price: PRICE_MARGIN_BOTTOM + priceFontSize * priceLineHeight,
    name: textHeight,
    sku: textHeight,
    excerpt: textHeight,
  };

  return display.reduce((height, key) => height + (heights[key] || 0), 0);
};

class ProductsWidget extends WidgetWrapper {
  init = (parent = dom.document) => {
    const elProductsList = dom.getCollection(this.selector, parent);
    const withAnimation = checkProjectWithAnimation();

    if (isEmpty(elProductsList)) return;

    elProductsList.forEach((elProductWrapper = {}, index) => {
      const { widgetId = null, settings } = elProductWrapper.dataset;

      if (widgetId && settings) {
        const {
          productId,
          hash,
          aspectRatio,
          display,
        } = JSON.parse(settings);

        this.initProduct(elProductWrapper, productId, hash, display, aspectRatio, index, withAnimation);
      }
    });
  };

  initProduct = (elProductWrapper, productId, hash, display, aspectRatio, index, withAnimation) => {
    const elProductTemplate = dom.getElement(`#product-template-${hash}`);
    const elProductRetryTemplate = dom.getElement(`#product-retry-${hash}`);

    if (!elProductTemplate) return;

    const renderer = new ItemsRenderer(elProductTemplate, {
      imports: { forEach, isNil },
    });
    const retryRenderer = elProductRetryTemplate ? new ItemsRenderer(elProductRetryTemplate) : null;

    const run = () => {
      dom.addHtml(elProductWrapper, '');
      dom.addClass(elProductWrapper, 'spinner');
      const widgetWidth = dom.getElementWidth(elProductWrapper);
      const minHeight = getProductMinHeight(
        widgetWidth,
        display,
        ASPECT_RATIO[aspectRatio]
      );

      dom.updateStyle(elProductWrapper, { minHeight: `${minHeight}px` });

      if (!productId) {
        dom.removeClass(elProductWrapper, 'spinner');
        dom.addHtml(elProductWrapper, '');

        return;
      }

      ecommerce.provider.getProduct(productId)
        .then((product) => {
          dom.removeClass(elProductWrapper, 'spinner');
          dom.updateStyle(elProductWrapper, { minHeight: null });
          dom.addHtml(elProductWrapper, renderer.render({
            product,
            withGallery: product.images.length > 1,
            imageSize: getImageSize(widgetWidth),
          }));
          (new SingleProduct(dom.getElement('.product', elProductWrapper), product)).init(index);

          if (withAnimation) {
            const elProductWidget = dom.getElement('.ecom-catalogue__product', elProductWrapper);

            connectInView(elProductWidget);
          }
        })
        .catch((error) => {
          console.error(error);
          const is404Error = error.message === PRODUCT_404_ERROR;

          dom.removeClass(elProductWrapper, 'spinner');

          if (retryRenderer && !is404Error) {
            dom.addHtml(
              elProductWrapper,
              retryRenderer.render({ items: [1], itemClassName: 'gallery-item' })
            );
            dom.on(dom.getElement('._try-btn', elProductWrapper), 'click', run);
          } else {
            dom.addHtml(elProductWrapper, '');
          }
        })
        .finally(() => {
          ieChangeCssVar();
        });
    };

    run();
  };
}

export default ProductsWidget;
