import { getVimeoVideoId } from '../utils';

import ProviderWrapper from './Wrapper';

class VideoVimeo extends ProviderWrapper {
  init = async () => {
    const isVideoExists = this.renderVideoOnPage();

    if (isVideoExists) return;

    // Valid Vimeo video id should be numbers in string format like '314648479'
    const { id } = this.dataParams;
    const pattern = /^\d+$/;
    const isCorrectId = pattern.test(id);
    const prepareId = isCorrectId
      ? id
      : await getVimeoVideoId(id);

    this.player = this.connectVideoDefault();
    this.addPreviewImage(this.player);
    this.player.source = {
      type: 'video',
      sources: [
        {
          src: prepareId,
          provider: 'vimeo',
        },
      ],
    };

    this.player.elements.poster.style.display = 'none';
  };
}

export default VideoVimeo;
