export const DEFAULT_BOOKING_STORE_URL = 'booking';

export const BOOKING_STORE_PAGE = 'BOOKING_STORE_PAGE';
export const BOOKING_SERVICE_PAGE = 'BOOKING_SERVICE_PAGE';

export const PAGE_TYPE = {
  BOOKING_STORE_PAGE,
  BOOKING_SERVICE_PAGE,
};

export const BOOKING_SECTION_ID = 'js-booking-section';
export const BOOKING_CATALOG_PAGE_WRAPPER_ID = 'js-booking-catalog-page-wrapper';
export const BOOKING_SERVICE_PAGE_WRAPPER_ID = 'js-booking-service-page-wrapper';
