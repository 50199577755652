import getStateValue from '../../helpers/getStateValue';

const bookingApiService = getStateValue('apiUrls.bookingApiUrl');

export const GET_SERVICE_PAGE_REGEX = (bookingUrl) => new RegExp(`/${bookingUrl}/\\S+-[0-9]+(\\/?)+(\\?|#|$)`, 'i');

export default {
  allProducts: (instanceId) => `${bookingApiService}/v1.0/appointment_type/all?siteId=${instanceId}`,
  categories: (instanceId) => `${bookingApiService}/v1.0/category/all?siteId=${instanceId}`,
};
