import { DEVICE_TYPES, getDeviceRatio } from '../../../device';
import { getDeviceType } from '../../helpers/browser';
import dom from '../../wrapper/DomWrapper';

class EmbedCode {
  constructor(embedBlock) {
    this.embedBlock = embedBlock;
  }

  init = () => {
    if (!this.embedBlock) return;

    const iframe = dom.getElement('iframe', this.embedBlock);

    const {
      settings,
    } = iframe.dataset;

    const { type } = JSON.parse(settings);

    if (type === 'link') {
      iframe.style.height = `${(iframe.clientHeight || 400) * getDeviceRatio(getDeviceType(), DEVICE_TYPES)}px`;
    }

    if (!iframe) return;

    dom.on(iframe, 'load', this.onIframeLoaded);
  };

  onIframeLoaded = () => {
    dom.removeClass(this.embedBlock, 'show_spinner');
  };
}

export default EmbedCode;
